import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import accountsItem from "@/modules/profile/section/accounts-item/index.vue";


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import uk from 'vue2-datepicker/locale/uk'
import ru from 'vue2-datepicker/locale/ru'

//sections

export default {
    name: "accounts",
    components: {
        mainSelect,
        DatePicker
    },
    comments: {
        accountsItem,

    },
    data() {
        return {
            tablesIndexes: ['0'],
            selectTime: '',
            selectType: '',
            dates: null,
            lang: uk
        }
    },

    created() {
        this.$route.params.lang === 'uk' ? this.lang = uk : this.lang = ru
        this.getDocuments({})
    },
    watch: {
        dates() {
            let obj = {}
            if (this.dates) {
                let dayFrom = this.dates[0].getDate(),
                    monthFrom = this.dates[0].getMonth() + 1,
                    yearFrom = this.dates[0].getUTCFullYear(),
                    dayTo = this.dates[1].getDate(),
                    monthTo = this.dates[1].getMonth() + 1,
                    yearTo = this.dates[1].getUTCFullYear();

                obj.date_from = `${yearFrom}-${monthFrom}-${dayFrom}`
                obj.date_to = `${yearTo}-${monthTo}-${dayTo}`

            }

            this.getDocuments(obj)
        }
    },
    computed: {
        ...mapGetters({
            userDocuments: 'profile/userDocuments',
            documentLoadingStatus: 'profile/documentLoadingStatus'
        })
    },
    filters: {
        /**
         * @param {number} value
         * @return {string}
         */
        formatDocPrice(value) {
            if (!value) {
                return '-'
            }
            const formatted = new Intl.NumberFormat('uk').format(value);

            if (Math.abs(Number.parseInt(formatted)) === 0) {
                return '-'
            }

            return formatted;
        }
    },
    methods: {
        ...mapActions({
            getDocuments: 'profile/GET_DOCUMENTS'
        }),
        isOpenedTable(...index) {
            return this.tablesIndexes.findIndex(el => el === index.join(',')) !== -1
        },
        openTable(...index) {
            let table = this.tablesIndexes.findIndex(el => el === index.join(','))
            table !== -1 ? this.tablesIndexes.splice(table, 1) : this.tablesIndexes.push(index.join(','))
        },
        openPopup(data) {
            this.isShowProfileDocument({status: true, data: data})
        },
        ...mapMutations({
            isShowProfileDocument: 'profile/SET_DOCUMENT_POPUP'
        }),
        // disabledData(date) {
        //     let today = new Date();
        //     today.setDate(today.getDate() - 1);
        //     return date < today;
        // },
    }
}
